import React from 'react';

import {
  getLanguagePrefix,
  renderWhenTrue,
} from '@lerna-monorepo/common/utils';
import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { PromoTextContent } from '@lerna-monorepo/common/components/promoTextContent/promoTextContent.component';
import { ImagesSectionContent } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.component';
import { PhotoGrid } from '@lerna-monorepo/common/components/photoGrid/photoGrid.component';
import { DescriptionSection } from '@lerna-monorepo/common/components/descriptionSection/descriptionSection.component';
import { ImagesSectionContentLayoutType } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.enums';
import { ImagesSectionContentData } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.types';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';
import { useImageSectionAnimation } from '@lerna-monorepo/common/utils/useImageSectionAnimation';

import {
  CaseStudyLinkSectionContainer,
  ImagesSectionContentContainer,
  LocalCaseStudySection,
  LocalLinkSection,
  HalfBox,
  PhotoGridContainer,
} from './homepage.styled';
import { HomepageData } from './homepage.types';

const IndexPage: React.FC<HomepageData> = (pageData: HomepageData) => {
  const {
    data,
    currentLanguage,
    newsBasePath,
    caseStudySettings,
    caseStudyBasePath,
    caseStudiesBasePaths,
  } = pageData.pageContext;
  const {
    headerContent,
    promoTextContent,
    imagesSectionContent,
    photoGrid,
    caseStudySection,
    linkSection,
    descriptionSection,
  } = data;
  const { handleScroll, sectionRef } = useScrollToSection();
  const { imageSectionRefs, setRefresh } = useImageSectionAnimation();
  const renderImagesSectionContent = renderWhenTrue(() =>
    imagesSectionContent.map(
      (imageSection: ImagesSectionContentData, index: number) => (
        <ImagesSectionContent
          key={imageSection.headerContent}
          buttonLabel={imageSection.buttonLabel}
          image={imageSection.image}
          icon={imageSection.icon}
          headerContent={imageSection.headerContent}
          positionType={imageSection.positionType}
          internalPage={imageSection.internalPage}
          externalPage={imageSection.externalPage}
          currentLanguage={currentLanguage}
          layoutType={
            index % 2 === 0
              ? ImagesSectionContentLayoutType.ImageContentLayout
              : ImagesSectionContentLayoutType.ContentImageLayout
          }
          imageSectionRefs={imageSectionRefs}
          setRefresh={setRefresh}
        />
      )
    )
  );

  const renderCaseStudySection = renderWhenTrue(() => (
    <LocalCaseStudySection
      header={caseStudySection.header}
      caseStudyBoxes={caseStudySection.caseStudyBoxes}
      boxesBaseUrl={caseStudyBasePath}
      currentLanguage={currentLanguage}
      basePaths={caseStudiesBasePaths}
    />
  ));

  const renderLinkSection = renderWhenTrue(() => (
    <LocalLinkSection
      header={linkSection.header}
      linkBoxes={linkSection.linkBoxes}
      boxesBaseUrl={`/${getLanguagePrefix(currentLanguage)}${newsBasePath}`}
    />
  ));

  return (
    <>
      <PhotoHeader
        subheaderContent={headerContent.subheaderContent}
        headerContent={headerContent.headerContent}
        buttonLabel={headerContent.buttonLabel}
        background={headerContent.background}
        videoBackground={headerContent.videoBackground}
        handleScroll={handleScroll}
      />
      <PromoTextContent
        headerContent={promoTextContent.headerContent}
        description={promoTextContent.description}
        promotionImage={promoTextContent.promotionImage}
      />
      <ImagesSectionContentContainer ref={sectionRef}>
        {renderImagesSectionContent(!!imagesSectionContent)}
      </ImagesSectionContentContainer>
      <PhotoGridContainer ref={sectionRef}>
        <PhotoGrid items={photoGrid} />
      </PhotoGridContainer>
      <CaseStudyLinkSectionContainer>
        {renderCaseStudySection(!!caseStudySection?.caseStudyBoxes)}
        {renderLinkSection(!!linkSection?.linkBoxes)}
        <HalfBox />
      </CaseStudyLinkSectionContainer>
      <DescriptionSection
        description={descriptionSection.description}
        buttonLabel={descriptionSection.buttonLabel}
        buttonLink={descriptionSection.buttonLink}
      />
    </>
  );
};

export default IndexPage;
